define('m13/models/application', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var lower = 0;
  var upper = 100000000;
  var calcId = Math.floor(Math.random() * (upper - lower)) + lower;

  var application = DS['default'].Model.extend({

    Calculation_ID: DS['default'].attr('string'),
    firm: DS['default'].attr('string'), //firm
    spracheinput: DS['default'].attr('string'), //spracheinput
    spracheoutput: DS['default'].attr('string'), //spracheoutput
    katalog: DS['default'].attr('string'), //katalog
    timberCode: DS['default'].attr('string'), //timberCode
    timberNA: DS['default'].attr('string'), //timberNA
    loadsCode: DS['default'].attr('string'), //loadsCode
    loadsNA: DS['default'].attr('string'), //loadsNA
    initialized: DS['default'].attr('boolean'),
    selectedScrew: DS['default'].attr('string'),
    treeLoaded: DS['default'].attr('boolean'),
    pdfErstellt: DS['default'].attr('boolean'),
    kennungland: DS['default'].attr('string'),
    userID: DS['default'].attr('string'),
    userName: DS['default'].attr('string')

  });

  application.reopenClass({
    FIXTURES: [{ id: calcId,
      Calculation_ID: "99999",
      firm: "WUERTH",
      // firm: "SWG",
      spracheinput: "DE",
      spracheoutput: "DE",
      katalog: "DE",
      timberCode: "EC5",
      timberNA: "DE",
      loadsCode: "EC1",
      loadsNA: "DE",
      initialized: false,
      treeLoaded: false,
      selectedScrew: "-1",
      pdfErstellt: false,
      kennungland: "DE",
      userID: "0815",
      userName: "derUserName"

    }]
  });

  exports['default'] = application;

});