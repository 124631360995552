define('m13/controllers/verbindungsmittel', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    schraubenliste: [],
    calcid: "",
    tooltiptext: "",
    loading: true,
    showinvalidscrews: false,
    validScrewSelected: false,
    jstreeStateOptionHash: {
      'key': ''
    },
    plugins: 'state',

    selectedNodes: "",

    infoText: "",
    keineSchraubenliste: true,

    init: function init() {

      this._super();

      var lasteinwirkung = this.controllerFor('lasteinwirkung');
      var bauteile = this.controllerFor('bauteile');
      if (!lasteinwirkung.get('lasteinwirkungInvalid') || !bauteile.get('bauteileInvalid')) {
        this.transitionToRoute('projektdaten');
      } else {
        console.log("lasteinwirkung ist valid");
      }
    },

    watchInvalidScrewsCombo: (function () {

      var self = this;

      if (self.showinvalidscrews) {

        console.log("hier werden die unzulässigen schrauben eingeblendet.");
        var schraubenliste = self.controllerFor('lasteinwirkung').get('schraubenliste');
        var core = treetools.buildTree(schraubenliste, true);
        self.set('schraubenliste', core);
      } else {
        console.log("und hier wieder aus.");
        var schraubenliste = self.controllerFor('lasteinwirkung').get('schraubenliste');
        var core = treetools.buildTree(schraubenliste, false);
        self.set('schraubenliste', core);
      }
    }).observes('showinvalidscrews'),

    watchSelectedScrew: (function () {

      var self = this;
      var application = self.controllerFor('application');
      var downloadcenter = self.controllerFor('downloadcenter');

      var ergebnis = self.controllerFor('ergebnis');

      console.log(self.jstreeStateOptionHash);

      if (self.selectedNodes.length > 0) {

        if (self.selectedNodes[0].data.VM_Okay === "True") {

          application.set('verbindungsmittelInvalid', false);
          application.set('schraubenInfo', self.selectedNodes[0].data.ProductInfo);
          application.set('schraubenCAD', self.selectedNodes[0].data.CAD);
          self.set('validScrewSelected', true);

          downloadcenter.set('schraubenInfo', self.selectedNodes[0].data.ProductInfo);
          downloadcenter.set('schraubenCAD', self.selectedNodes[0].data.CAD);
          downloadcenter.set('validScrewSelected', true);

          ergebnis.set('pdfErstellt', false);

          this.ergebnisgrafik();
        } else {

          application.set('verbindungsmittelInvalid', true);
          self.set('validScrewSelected', false);
          downloadcenter.set('validScrewSelected', false);

          var application = this.controllerFor('application');
          var x3d = application.get('model').x3ddefault.objectAt(0);
          var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

          x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
          x3d.set('ergebnisGeladen', false);
          x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
          x3d.set('transformHelper', !x3d.get('transformHelper'));
        }
      }
    }).observes('selectedNodes'),

    ergebnisgrafik: function ergebnisgrafik() {

      var self = this;

      var selectedScrew = self.selectedNodes[0].data.ArtNr;
      var cidGrafik = self.calcid;

      var application = self.controllerFor('application');
      var store = application.get('store');
      var arrayHelper = [];

      var x3d = application.get('model').x3ddefault.objectAt(0);

      // alte Schrauben "Verbindung (has-many)" aus x3ddefault.get('bauteile') löschen
      application.get('model').x3ddefault.objectAt(0).get('bauteile').forEach(function (item) {
        if (item.get('typ') === 'verbindungsmittel') {
          arrayHelper.pushObject(item);
        }
      });
      arrayHelper.forEach(function (item) {
        application.get('model').x3ddefault.objectAt(0).get('bauteile').removeObject(item);
      });

      console.log('cidGrafik: ' + cidGrafik);
      console.log('selectedScrew: ' + selectedScrew);

      var server = application.get('server');
      var pfad = application.get('pfad');

      $.ajax({
        type: "POST",
        url: server + "m13/DE/getGrafik/",
        data: {
          cid: cidGrafik,
          artnr: selectedScrew
        }
      }).done(function (data) {

        console.log('Daten der ausgewählten Schraube');
        console.log(data);

        var dataObject = JSON.parse(data);

        dataObject.bauteile.forEach(function (item) {

          var newID = item.id;
          if (item.typ === "verbindungsmittel") {
            newID = "schraubeStandard1";
          }

          // console.log('vermidugsmittel '+ item.id + ' anlegen');

          if (item.typ === "verbindungsmittel") {

            var bauteil = store.push('bauteil', {
              id: item.id,
              typ: item.typ,
              punkt: [item.point.objectAt(0)],
              translations: [item.point.objectAt(0)],
              rotations: [item.richtungsvektor],
              richtungsvektor: [item.richtungsvektor],
              appearances: [newID],
              diffusecolors: [newID],
              screwProperties: [item.verbindungsmittel]
            });

            bauteil.save();
            x3d.get('bauteile').pushObject(bauteil);
          }

          // dataObject.richtungsvektoren.forEach(function(rVektor){
          //
          //   if (rVektor.id === item.richtungsvektor){
          //     var rotation = store.push('rotation', rVektor);
          //     rotation.save();
          //
          //     var yWinkel = self.getWinkel({x: rVektor.x, y: 0, z: rVektor.z}, {x: 0, y: 0, z:1});
          //     var zWinkel = self.getWinkel({x: rVektor.x, y: rVektor.y, z: rVektor.z}, {x: 0, y: 1, z:0});
          //
          //     x3d.get('bauteile').findBy('id', item.id).get('rotations').objectAt(0).set('x', rVektor.x)
          //     x3d.get('bauteile').findBy('id', item.id).get('rotations').objectAt(0).set('y', yWinkel)
          //     x3d.get('bauteile').findBy('id', item.id).get('rotations').objectAt(0).set('z', zWinkel)
          //     x3d.get('bauteile').findBy('id', item.id).get('rotations').objectAt(0).set('winkel', 0)
          //   }
          // }); //Richtungsvektoren
        }); //Bauteile

        // console.log('richtungsvektoren anlegen');

        dataObject.richtungsvektoren.forEach(function (item) {
          var r1 = store.push('rotation', item);
          r1.save();

          var r2 = store.push('richtungsvektor', item);
          r2.save();
        });

        dataObject.punkte.forEach(function (item) {

          var t = store.push('translation', item);
          t.save();

          var p = store.push('punkt', item);
          p.save();
        });

        // console.log('Schraubeneigenschaftenm anlegen');

        dataObject.verbindungsmittel.forEach(function (item) {
          var screwProp = store.push('screw-property', item);
          screwProp.save();
        });

        // console.log('Abstände berechnen');

        x3d.set('n90', Number(dataObject.n90[0].replace(",", ".")));
        x3d.set('Randabstand', Number(dataObject.Randabstand[0].replace(",", ".")));
        x3d.set('a1', Number(dataObject.a1[0].replace(",", ".")));
        x3d.set('AbstaendeZusammengefasst', dataObject.AbstaendeZusammengefasst[0].replace(",", "."));
        x3d.set('AbstandMitte', dataObject.AbstandMitte[0].replace(",", "."));

        // console.log('Schrauben übernommen');

        x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
        x3d.set('ergebnisGeladen', true);
        x3d.set('aktuellerSchraubenTyp', 'verbindungsmittel');

        self.controllerFor('application').zentriertObjekt();
        self.controllerFor('application').zentriertObjekt();
      });
    },

    getWinkel: function getWinkel(vektor, bezugsVektor) {
      var self = this;

      var xx = vektor.x * bezugsVektor.x;
      var yy = vektor.y * bezugsVektor.y;
      var zz = vektor.z * bezugsVektor.z;

      var l_vektor = self.vektorLaenge(vektor);
      var l_bezugsVektor = self.vektorLaenge(bezugsVektor);

      var winkel = Math.acos((xx + yy + zz) / (l_vektor * l_bezugsVektor));

      return winkel;
    },

    vektorLaenge: function vektorLaenge(v) {
      return Math.sqrt(Math.pow(v.x, 2) + Math.pow(v.y, 2) + Math.pow(v.z, 2));
    },

    actions: {
      proceedInput: function proceedInput(event) {

        var self = this;

        var selectedScrew = self.selectedNodes[0].data.ArtNr;
        var application = self.controllerFor('application');
        var applicationdata = application.get('model').application.objectAt(0);
        var cid = applicationdata.get('Calculation_ID');

        applicationdata.set('selectedScrew', selectedScrew);

        self.set('loading', true);

        var zusatz = self.controllerFor('supercontroller').erzeugeAufrufzusatz(true);

        var server = application.get('server');
        var pfad = application.get('pfad');

        $.ajax({
          type: "POST",
          url: server + "m13/DE/getKompaktergebnis/" + zusatz,
          data: {
            cid: self.calcid,
            artnr: selectedScrew
          }
        }).done(function (data, statusText, xhr) {

          self.controllerFor('supercontroller').logaufruf("getKompaktergebnis", xhr.status);

          var ergebnis = self.controllerFor('ergebnis');

          var downloadcenter = self.controllerFor('downloadcenter');
          downloadcenter.set('pdfErstellt', false);

          var html = data;
          var bEnd, bStart;
          bStart = html.indexOf("<div id='headline'");
          bEnd = html.indexOf("</body");
          var body = html.slice(bStart, bEnd);

          ergebnis.set('kompaktergebnis', body.toString());
          ergebnis.set('pdfErstellt', false);
          ergebnis.send('setKompaktergebnis');
          self.transitionToRoute('ergebnis');
          self.set('loading', false);
        });
      },

      logNode: function logNode(event) {
        var self = this;
        console.log(self.selectedNodes);
        console.log(self.selectedNodes[0].text);
      }
    }
  });

});